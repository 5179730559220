import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Footer.scss';
import AreaData from '../../Components/ServicesPage/AreaData'
import LocationData from '../../Components/LocationAll/LocationData'


export default function Footer({ WebSitePages, navigateTo }) {
    const Navigate = useNavigate()
    return (
        <div className="Footer">
            <div className="Footer2">
                <div className="Footer2Logo" onClick={() => navigateTo("")}>
                    <h1><b>Torrance</b> Chimney Sweep</h1>
                </div>
            </div>
            <div className="FooterTop">

                <div className="SiteMapDiv">
                    <h1>Torrance Chimney Sweep</h1>
                    <div className="SiteMapDivList">
                        <a href="/">Home Page</a>
                        <a href="/book">Book Chimney Sweep Appointment Online In Torrance, California</a>
                        <a href="/locations">Locations</a>
                        <a href={`/reviews/${LocationData.find(a => a.City == ("Torrance").replace("%20", '')).City.replace(" ", '').toLowerCase()}`}>Reviews</a>
                        <a href="/contact">Contact Us</a>
                        <a href="/blog">Blog</a>
                        <a href="/services">Services</a>
                        <a href="/faq">Faq</a>
                        <a href="/careers">Careers</a>
                        <a href="/sitemap">Site Map</a>
                    </div>
                </div>
                <div className="SiteMapDiv">
                    <h1>Services</h1>
                    <div className="SiteMapDivList">
                        {AreaData.map((A, index) =>
                            <a className="Header1BoxMenuBox" href={`${A.Path}`}>{A.Title}</a>
                        )}
                    </div>
                </div>
                <div className="SiteMapDiv">
                    <h1>Contact Us</h1>
                    <div className="SiteMapDivList">
                        <a target='_blank' href="">
                            Torrance, California
                        </a>
                        <a target='_blank' href="tel:424-260-9781">
                            424-260-9781
                        </a>
                        <a target='_blank' href="mailto:info@torrancechimneysweep.org">
                            info@torrancechimneysweep.org
                        </a>
                    </div>
                </div>

            </div>
            <div className="Footer3">
                <h1>Copyright © 2023 Torrance Chimney Sweep. All rights reserved.</h1>
            </div>
        </div>
    )
}
