import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import './ServicesAll.scss'
import { Helmet } from "react-helmet";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useNavigate } from 'react-router-dom';
import AreaData from '..//ServicesPage/AreaData'
import Checklist from '../../Images/checklist.png'
import Google from '../../Images/Google.png'
import book from '../../Images/book.png'
import telephone from '../../Images/telephone.png'
import chimneysweep from '../../Images/chimneysweep.jpeg'

export default function ServicesAll() {
    const navigate = useNavigate()


    return (
        <div className="ServicesAll">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Torrance Chimney Sweep | Our Chimney Sweep Services</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Torrance Chimney Sweep | Our  Services" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Torrance Chimney Sweep | Our  Services" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.torrancechimneysweep.org/services" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Discover Our Services At Torrance Chimney Sweep Services, we understand that your Chimney Sweep is more than just an entryway; it's an essential part of your home's security, functionality, and curb appeal." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Discover Our Services At Torrance Chimney Sweep Services, we understand that your Chimney Sweep is more than just an entryway; it's an essential part of your home's security, functionality, and curb appeal." data-react-helmet="true" />
                <meta name="keywords" content="chimney sweep, chimney Inspection, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, chimney services, chimney service, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Insulation, Chimney Installation, Chimney Liner Repair, Chimney Pointing, Chimney Rain Cap Installation, Chimney Repair, Chimney Crown Repair, Chimney Restoration, Chimney Flue Installation, Chimney Flue Repair, ​Chimney Construction, Chimney Vent Installation, Chimney Flashing, Flexible Chimney Liner Installation, Chimney Damper Repair, Chimney Cap Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rain Cap Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplace Cap" data-react-helmet="true" />
            </Helmet>
            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>Chimney Sweep SERVICES</h1>
                    <h2>Welcome to Torrance's Premier Chimney Sweep Service Provider</h2>
                    <h3>Discover Our Services At Torrance Chimney Sweep Services, we understand that your Chimney Sweep is more than just an entryway; it's an essential part of your home's security, functionality, and curb appeal. Whether you need a quick repair, a complete replacement, or regular maintenance, our experienced team is here to provide you with reliable and affordable solutions.</h3>
                    <span>
                        <a href="tel:424-260-9781">CALL 424-260-9781<img src={telephone} alt="" /></a>
                        <a href="/book">BOOK AN APPOINTMENT<img src={book} alt="" /></a>
                    </span>
                    <span className="GoogleButt">
                        <img src={Google} alt="Torrance Chimney Sweep Reviews" />
                        <h6>4.9 Stars - Based on 1,583 Reviews</h6>
                    </span>
                </div>
                <div className="MainBannerRight">
                    <img src={chimneysweep} alt="Expert Chimney Sweep Services | Torrance Chimney Sweep" />
                    <div className="MainBannerRightBubble1"></div>
                    <div className="MainBannerRightBubble2"></div>
                </div>
            </div>
            <div className="ServicesAllTitle">
                <h1>Torrance Chimney Sweep Services in Torrance, CA</h1>
            </div>
            <div className="ServicesAllLsit">
                {AreaData.map((A, index) =>
                    <a href={A.Path} className="Header1BoxMenuBox">
                        
                        <div className="ServicesAllBox">
                            <h2>{A.Title}</h2>
                            <h6>{A.Des}</h6>
                            <h4>Torrance Chimney Sweep Serving {A.Title} in Torrance, CA area and surrounding communities.</h4>
                            <h5>EXPLORE THIS SERVICE</h5>
                        </div>
                    </a>
                )}
            </div>

            <NavigatorPath />

        </div>
    )
}

